import React, { useEffect, useState } from 'react';
import '../Styles/DonationForm.css';

const DonationForm = () => {
    const [amount, setAmount] = useState(0);

    // Fetch the donation amount from local storage
    useEffect(() => {
        window.scrollTo(0, 0); // Ensures the page starts at the top
        const savedAmount = window.localStorage.getItem("donationAmount");
        setAmount(savedAmount ? parseFloat(savedAmount) : 0);
    }, []);

    return (
        <div className="donation-form">
            <img src="/sudama-krishna.jpg" alt='donation quote' />
            <h1>Help Us Build A Glorious Temple For Shri Radha Krishna</h1>
            <h3>Get Divine Blessings from Sri Sri Radha Madhav</h3>
            <p>Amount to be Donated: ₹{amount}</p>
            <form>
                <div class="form-container">
                    <div class="row">
                        <div class="form-group">
                            <label>Name:</label>
                            <input type="text" required />
                        </div>
                        <div class="form-group">
                            <label>Email:</label>
                            <input type="email" required />
                        </div>
                        <div class="form-group">
                            <label>Phone:</label>
                            <input type="tel" required />
                        </div>
                        <div class="form-group">
                            <label>Country:</label>
                            <input type="text" required />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label>State:</label>
                            <input type="text" required />
                        </div>
                        <div class="form-group">
                            <label>City:</label>
                            <input type="text" required />
                        </div>
                        <div class="form-group">
                            <label>Pin Code:</label>
                            <input type="text" required />
                        </div>
                        <div class="form-group">
                            <label>PAN Number:</label>
                            <input type="text" maxLength="10" required />
                        </div>
                    </div>
                </div>
                {/* <div><label>Message:</label></div>
                <textarea rows="2" placeholder="Enter your message here"></textarea> */}

                <div><button type="submit" className='btn'>Confirm Donation</button></div>
            </form>
        </div>
    );
};

export default DonationForm;

